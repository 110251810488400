import React, { useContext, useState } from "react"
import { graphql } from "gatsby"
import { WrapperContext } from "../components/Layout"
// import PubSub from 'pubsub-js';
//import cookie from 'react-cookies'
import SEO from "../components/seo"
import ProjectCard from "../components/ProjectCard"
// import ProjectFilters from '../components/ProjectFilters'

const PageHome = props => {
  const _WrapperContext = useContext(WrapperContext)
  const { projects } = _WrapperContext

  const {
    options,
    homepage,
    // projects
  } = props.data

  // console.log(options.data)
  return (
    <div className="home">
      <SEO
        pageTitle={homepage.data.title.text}
        pageDescription={homepage.data.texte.text}
        template="template-home"
        pageBanner={options.data.banner.url}
        // pathname={location.pathname}
      />

      <div className="projects-grid">
        {/* <div className="sep1"></div> */}
        <div className="cont">
          <div className="row">
            {projects.edges.map(({ node }, i) => (
              <ProjectCard input={node} key={i} />
            ))}
            {/* {projects.edges.map(({ node }, i) => (
              <ProjectCard input={node} key={i} />
            ))}
            {projects.edges.map(({ node }, i) => (
              <ProjectCard input={node} key={i} />
            ))}
            {projects.edges.map(({ node }, i) => (
              <ProjectCard input={node} key={i} />
            ))}
            {projects.edges.map(({ node }, i) => (
              <ProjectCard input={node} key={i} />
            ))} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageHome

export const pageQuery = graphql`
  query homePage {
    options: prismicOptions {
      ..._prismicOptions
    }
    homepage: prismicHomepage {
      data {
        title {
          text
        }
        texte {
          text
        }
      }
    }
    # projects: allPrismicProject {
    #   edges {
    #     node {
    #       uid
    #       data {
    #         ...projet
    #       }
    #     }
    #   }
    # }
  }
`
