import React, { useState, useRef, useEffect, useContext } from "react"
import Img from "gatsby-background-image"
import PubSub from "pubsub-js"
import { FiltersContext } from "./Layout"

const ProjectCard = props => {
  const [index, setIndex] = useState(0)
  const [cardClass, setCardClass] = useState("loading")
  // const [active, setActive] = useState(false)
  const slider = useRef()
  const heading = useRef()
  const mobileBtnInit = useRef()

  const _FiltersContext = useContext(FiltersContext)
  const { filters } = _FiltersContext

  const { data, uid } = props.input
  const {
    title,
    chapo,
    texte,
    fiche_technique,
    images,
    program,
    year,
    location,
  } = data

// console.log(title)
// console.log(images)

  const arrProgram = program.indexOf(",") > -1 ? program.split(",") : [program]
  const locationTitre = location.document[0].data.titre.text
  const len = images.length + 2
  // console.log(arrProgram)
  useEffect(() => {
    // if(document.documentElement.classList.contains("touch")){
    //   setIndex(-1)
    // }
    const token = PubSub.subscribe("SLIDER.SLIDE", (e, _uid) => {
      if (_uid !== uid) {
        // console.log(_uid, uid, index)
        setIndex(0)
      }
    })
    return () => {
      PubSub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    // console.log("useEffect index", index, uid)
    _udpateSlider()
    if (index > 0) PubSub.publish("SLIDER.SLIDE", uid)//reset other cards
  }, [index])

  const _prev = () => {
    const newIndex = index - 1 >= 0 ? index - 1 : len - 1
    setIndex(newIndex)
  }

  const _next = () => {
    const newIndex = index + 1 < len ? index + 1 : 0
    // console.log("_next",newIndex)
    setIndex(newIndex)
  }

  const _mobileInit = () => {
    heading.current.style.display = "none"
    mobileBtnInit.current.style.display = "none"
  }

  const _udpateSlider = () => {
    // console.log("_udpateSlider", index, len)
    //
    const headingDisplay = index > 0 ? "none" : "block"
    heading.current.style.display = headingDisplay
    // console.log("_udpateSlider")
    slider.current.querySelectorAll(".slide").forEach((element, i) => {
      if (i === index) element.classList.remove("hidden")
      else element.classList.add("hidden")
    })
  }

  const _onMouseLeave = () => {
    // console.log("_onMouseLeave")
    setIndex(0)
  }

  const _getIsActive = () => {
    // console.table(filters)
    //  console.log(arrProgram)
    const isInProgram =
      filters.program.length === 0
        ? true
        : arrProgram.some(e => filters.program.includes(e))
    // console.log(filters.program)
    const isInYear =
      filters.year.length === 0 ? true : filters.year.indexOf(year) > -1
    const isInLocation =
      filters.location.length === 0
        ? true
        : filters.location.indexOf(locationTitre) > -1
    // console.log(title.text, isInProgram && isInYear && isInLocation)
    // if(filters.category.length > 0)
    return isInProgram && isInYear && isInLocation
  }

  const _reveal = () => {
    setTimeout(() => {
      setCardClass("")
    }, 250)
  }
// console.log("index", index)
  const isActive = _getIsActive()
  return (
    <div
      className={
        "card col-xs " +
        cardClass +
        " " +
        (isActive ? "is-active" : "isnt-active")
      }
      onMouseLeave={_onMouseLeave}
    >
      <div className="card-ar" onClick={_next}>
        <div className="inner">
          <div
            className="card-bg"
            style={{
              backgroundImage: `url(${images[0].image.url})`,
            }}
          ></div>
          <div className="card-slider" ref={slider}>
            {/* <div className="slide">
                            <Img 
                                fluid={images[0].image.localFile.childImageSharp.fluid} 
                                alt={images[0].image.alt}
                                // objectFit="cover"
                                // objectPosition="50% 50%"
                                // backgroundColor="#97D3FF"
                                fadeIn={false}
                                onLoad={_reveal}
                                />
                        </div> */}
            {images.map(({ image }, i) => (
              <div className="slide " key={i}>
                <Img
                  fluid={image.localFile.childImageSharp.fluid}
                  alt={image.alt}
                  fadeIn={false}
                  // durationFadeIn={0}
                  // objectFit="cover"
                  // objectPosition="50% 50%"
                  // backgroundColor="#97D3FF"
                  onLoad={_reveal}
                />
              </div>
            ))}
            <div className="slide ">
              <div className="slide-texte h100">
                <div className="x xdc xjb h100">
                  <div
                    className="texte chapo fCond fM"
                    dangerouslySetInnerHTML={{ __html: chapo.html }}
                  ></div>
                  <div
                    className="texte texte-content"
                    dangerouslySetInnerHTML={{ __html: texte.html }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="slide ">
              <div className="slide-texte h100">
                <div className="fiche-technique h100">
                  <div className="x xdc xjb h100">
                    <ul>
                      {fiche_technique.map((li, i) => (
                        <li className="x" key={i}>
                          <div className="label fCond ttu">{li.label.text}</div>
                          <div
                            className="value"
                            dangerouslySetInnerHTML={{ __html: li.value.html }}
                          ></div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-header h100 " ref={heading}>
            <h2 className="fXL">
              {title.text}, {locationTitre}
            </h2>
          </div>
          <div className="card-ghost h100 " ref={heading}>
            <div className="fXL headline">
              {title.text}, {locationTitre}
            </div>
          </div>
          {/* 
          <div className="controls prev" onClick={_prev}></div>
          <div className="controls next" onClick={_next}></div> */}
        </div>
      </div>
      <div className="xxs-only w100 h100 psa t0" ref={mobileBtnInit} onClick={_mobileInit}></div>
    </div>
  )
}

export default ProjectCard
